<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'
import GuestLayout from './layouts/GuestLayout'

export default {
  components: { MainLayout, EmptyLayout, GuestLayout },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Excourse'
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'Empty') + 'Layout'
    }
  },
  methods: {
    getFirstLang: function () {
      if (typeof localStorage.getItem('lang') === 'undefined' || localStorage.getItem('lang') === null) {
        localStorage.setItem('lang', 0)
      }
    }
  },
  mounted () {
    this.getFirstLang()
  }
}
</script>

<style lang="scss">
@import "assets/css/index.scss";
</style>
