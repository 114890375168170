<template>
  <footer class="footer">
    <ul>
      <li>
        <img src="img/visa-and-mastercard-logo-26.png" alt="">
      </li>
      <li
        v-for="(item, index) in list"
        :key="item"
        class="medium_xs"
        @click="openModal(index)">
        <p>{{item}}</p>
      </li>
    </ul>
    <ModalComponent
      v-if="isModalOpen"
      @close-modal="closeModal()">
      <template v-slot:head>
        {{modalTitle}}
      </template>
      <template v-slot:body>
        <div class="info_media">
          <div v-if="selectedDoc === 0" class="info_media_right">
            <iframe title="" src="/pdf/Патент на Excourse.pdf#toolbar=0" width="100%" height="500px" />
          </div>
          <div v-if="selectedDoc === 1">
            <iframe title="" src="/pdf/Обработка персональных данных.pdf#toolbar=0" width="100%" height="500px" />
          </div>
          <div v-if="selectedDoc === 2">
            <iframe title="" src="/pdf/Политика конфиденциальности.pdf#toolbar=0" width="100%" height="500px" />
          </div>
          <div v-if="selectedDoc === 3">
            <iframe title="" src="/pdf/Оферта по оказанию услуг.pdf#toolbar=0" width="100%" height="500px" />
          </div>
          <div v-if="selectedDoc === 4">
            <iframe title="" src="/pdf/Безопасностьонлайнплатежей.pdf#toolbar=0" width="100%" height="500px" />
          </div>
          <div v-if="selectedDoc === 5">
            <iframe title="" src="/pdf/Реквизиты.pdf#toolbar=0" width="100%" height="500px" />
          </div>
        </div>
      </template>
    </ModalComponent>
  </footer>
</template>

<script>
import ModalComponent from '@/components/modal/ModalComponent'
export default {
  name: 'FooterPage',
  components: {
    ModalComponent
  },
  data () {
    return {
      selectedDoc: 0,
      isModalOpen: false,
      modalTitle: '',
      list: [`${this.$t('all_right_reserved')}`, `${this.$t('personal_data')}`, `${this.$t('confidentiality')}`, `${this.$t('offer')}`, `${this.$t('online_payments_security')}`, `${this.$t('requisites')}`]
    }
  },
  methods: {
    // modal
    closeModal: function () {
      this.isModalOpen = false
    },
    openModal: function (ind) {
      this.isModalOpen = true
      this.modalTitle = this.list[ind]
      this.selectedDoc = ind
    }
  }
}
</script>
