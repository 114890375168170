<script setup>
import { computed } from 'vue'
import { LOGO_WIDE, LOGO_SHORT, SCREEN_LG } from '../../store/constants'

const logoName = computed(() => window.innerWidth <= SCREEN_LG ? LOGO_SHORT : LOGO_WIDE)
</script>

<template>
  <div class="flex">
    <img :src="`/img/${logoName}`" class="img" alt="logotip" loading="lazy">
  </div>
</template>
