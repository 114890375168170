<template>
  <div @click="checkAccessDate">
    <ForMainLayout
      @hide-or-show="menuOpen"
      @my-profile="toMyProfile"
      :isOpen="isOpen"/>
    <div class="flex">
      <Navbar
        class="navbar_visible"
        :isProfile="isProfile"
        @close-modal="closeModal"
        @open-onboard="openModal"
        @access-check="checkAccessDate"/>
      <OpenedNavbar
        class="menu_invisible"
        ref="menu"
        v-if="isOpen"
        @hide-or-show="menuOpen"/>
      <router-view/>
    </div>
    <FirstWind
      v-if="uData.rle === 'student'"
      :next="next.first"
      @next-wind="switchWind"/>
    <SecondWind
      :next="next.second"
      :percent="percent"
      :firstSection="firstSection"
      :secondSection="secondSection"
      :thirdSection="thirdSection"
      @next-wind="selectSection"/>
    <ThirdWind
      :next="next.third"
      @next-wind="switchWind"/>
    <FourthWind
      :next="next.fourth"
      @next-wind="switchWind"/>
    <FifthWind
      :next="next.fifth"
      @next-wind="switchWind"/>
    <SixthWind
      :next="next.sixth"
      @next-wind="switchWind"/>
    <SeventhWind
      :next="next.seventh"
      @next-wind="switchWind"/>
    <EighthWind
      :next="next.eighth"
      @next-wind="switchWind"/>
    <NinthWind
      :next="next.ninth"
      @next-wind="switchWind"/>
    <ModalComponent720
      v-if="isModal720Open"
      :closed="uData.fst_visit"
      @close-modal="closeModal()">
      <template v-slot:head>
        {{$t('onboard')}}
      </template>
      <template v-slot:body>
        <!-- <p>{{$t('video_warn')}}</p> -->
        <iframe class="onboard_v"
          title=""
          src="https://www.youtube.com/embed/2sB8mJQOcsI?si=NYiddwpHEcN8hlyk"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          picture-in-picture></iframe>
        <div
          v-if="!uData.fst_vist"
          class="flex jc_sp_c">
          <button class="flex bttn-primary brdr_r_8" @click="closeModal">
            {{$t('onboard_button')}}
          </button>
        </div>
      </template>
    </ModalComponent720>
    <user-data-modal
      :isOpen="isOpenUserData"
      @check-edu="getEducationById"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import ForMainLayout from '@/components/ForMainLayout'
import OpenedNavbar from '@/components/OpenedNavbar'
import FirstWind from '@/components/onboarding/FirstWind'
import SecondWind from '@/components/onboarding/SecondWind'
import ThirdWind from '@/components/onboarding/ThirdWind'
import FourthWind from '@/components/onboarding/FourthWind'
import FifthWind from '@/components/onboarding/FifthWind'
import SixthWind from '@/components/onboarding/SixthWind'
import SeventhWind from '@/components/onboarding/SeventhWind'
import EighthWind from '@/components/onboarding/EighthWind'
import NinthWind from '@/components/onboarding/NinthWind'
import ModalComponent720 from '@/components/modal/ModalComponent720'
import UserDataModal from '../components/user/UserDataModal.vue'
export default {
  name: 'MainLayout',
  components: { Navbar, ModalComponent720, ForMainLayout, OpenedNavbar, FirstWind, SecondWind, ThirdWind, FourthWind, FifthWind, SixthWind, SeventhWind, EighthWind, NinthWind, UserDataModal },
  data () {
    return {
      uData: JSON.parse(localStorage.getItem('uData')),
      isOpen: false,
      isProfile: false,
      langInd: ['ru', 'kz', 'en'],
      next: {
        first: false,
        second: true,
        third: true,
        fourth: true,
        fifth: true,
        sixth: true,
        seventh: true,
        eighth: true,
        ninth: true,
        tenth: true
      },
      percent: 0,
      firstSection: '',
      secondSection: '',
      thirdSection: '',
      isModal720Open: false,
      educationList: [],
      isFuncDone: false,
      counter: 0
    }
  },
  computed: {
    isOpenUserData () {
      return this.uData.rle === 'student' && !this.isFuncDone && !this.educationList.length
    }
  },
  methods: {
    selectSection: function (arr) {
      switch (arr[0]) {
        case 'first': this.switchWind(arr[1])
          this.firstSection = arr[0]
          break
        case 'second': this.switchProfile()
          this.secondSection = arr[0]
          break
        case 'third': this.switchOnboard()
          this.thirdSection = arr[0]
          break
      }
    },
    switchProfile: function () {
      this.next.eighth = false
      this.next.second = true
      this.percent += 33.4
    },
    switchOnboard: function () {
      this.next.second = false
      this.openModal()
    },
    switchWind: function (numb) {
      switch (numb) {
        case 1: this.next.first = true
          this.next.second = false
          break
        case 2: this.next.second = true
          this.next.third = false
          break
        case 3: this.next.third = true
          this.next.fourth = false
          break
        case 4: this.next.fourth = true
          this.next.fifth = false
          break
        case 5: this.next.fifth = true
          this.next.sixth = false
          break
        case 6: this.next.sixth = true
          this.next.seventh = false
          break
        case 7: this.next.seventh = true
          this.next.second = false
          this.percent += 33.3
          break
        case 8: this.next.eighth = true
          this.next.second = false
          break
        case 9: this.next.second = true
          this.next.ninth = false
          break
      }
    },
    checkAccessDate: function () {
      const date = new Date().toISOString().slice(0, 10)
      if (this.uData.tm_in !== date) {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
      }
    },
    checkIsActiveSchool: function () {
      // this.uData.tf_st ? this.isTariffActive = true : this.isTariffActive = false
      if (!this.uData.tf_st) {
        this.$router.push('/guest-catalog').catch(() => { })
      }
    },
    localLanguage: function () {
      this.$i18n.locale = this.langInd[localStorage.getItem('lang')]
    },
    menuOpen: function (item) {
      this.isOpen = item
      // const menu = this.$refs.menu
    },
    toMyProfile: function () {
      this.isProfile = !this.isProfile
    },
    openModal: function () {
      this.isModal720Open = true
    },
    closeModal: function () {
      this.isModal720Open = false
      this.percent += 33.3
    },
    async getEducationById () {
      await this.$store.dispatch('getEducation', '')
        .then((response) => {
          this.educationList = response.data
          if (this.counter === 0 && !this.educationList.length) {
            this.counter = 1
            this.getEducationById()
          } else if (this.educationList.length) {
            this.isFuncDone = true
          }
        })
        .catch(err => {
          this.isFuncDone = true
          this.error = err.response.data.message
        })
    }
  },
  mounted () {
    this.checkAccessDate()
    if (this.uData.rle === 'student') {
      this.getEducationById()
    }
  }
}
</script>
