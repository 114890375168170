import i18n from '@/i18n/index.js'

const datas = {
  flags: ['/icons/Rus.svg', '/icons/Qazaq.svg', '/icons/Eng.svg', '/icons/Turkey.svg', '/icons/Germany.svg', '/icons/Hungary.svg', '/icons/Italy.svg', '/icons/grecee.png'],
  color: ['orange', 'green', 'blue', 'pink', 'purple', 'yellow', 'mermaid'],
  // optionList: [`${i18n.t('by_default')}`, `${i18n.t('start_expensive')}`, `${i18n.t('start_cheap')}`],
  colorCount: 7,
  tags: [
    [{
      tagName: 'tag',
      tagField: 'tag_name',
      index: 0
    },
    {
      tagName: 'city',
      tagField: 'city',
      index: 1
    }],
    [{
      tagName: 'university',
      tagField: 'name',
      index: 2
    },
    {
      tagName: 'univer_tag',
      tagField: 'name',
      index: 3
    }],
    [{
      tagName: 'degree',
      tagField: 'degree_name',
      index: 4
    },
    {
      tagName: 'speciality',
      tagField: 'name',
      index: 5
    }],
    [{
      tagName: 'format',
      tagField: 'format_name',
      index: 6
    }]
  ],
  // simpleLanguageList: [`${i18n.t('rus')}`, `${i18n.t('kazakh')}`, `${i18n.t('eng')}`, `${i18n.t('turk')}`, `${i18n.t('nem')}`, `${i18n.t('venger')}`, `${i18n.t('italy')}`, `${i18n.t('greece')}`],
  languageListPop: [{
    name: `${i18n.t('eng')}`,
    index: 2
  },
  {
    name: `${i18n.t('turk')}`,
    index: 3
  },
  {
    name: `${i18n.t('nem')}`,
    index: 4
  },
  {
    name: `${i18n.t('venger')}`,
    index: 5
  },
  {
    name: `${i18n.t('italy')}`,
    index: 6
  },
  {
    name: `${i18n.t('greece')}`,
    index: 7
  },
  {
    name: `${i18n.t('rus')}`,
    index: 0
  },
  {
    name: `${i18n.t('kazakh')}`,
    index: 1
  }],
  languageList: [{
    name: `${i18n.t('rus')}`,
    index: 0
  },
  {
    name: `${i18n.t('kazakh')}`,
    index: 1
  },
  {
    name: `${i18n.t('eng')}`,
    index: 2
  },
  {
    name: `${i18n.t('turk')}`,
    index: 3
  },
  {
    name: `${i18n.t('nem')}`,
    index: 4
  },
  {
    name: `${i18n.t('venger')}`,
    index: 5
  },
  {
    name: `${i18n.t('italy')}`,
    index: 6
  },
  {
    name: `${i18n.t('greece')}`,
    index: 7
  }],
  fProperty: ['full_desc_ru', 'full_desc_kk', 'full_desc_en'],
  shProperty: ['short_desc_ru', 'short_desc_kk', 'short_desc_en'],
  fPlaceholdings: [`${i18n.t('full_course_desc_ru')}`, `${i18n.t('full_course_desc_kz')}`, `${i18n.t('full_course_desc_en')}`],
  shPlaceholdings: [`${i18n.t('short_course_desc_ru')}`, `${i18n.t('short_course_desc_kz')}`, `${i18n.t('short_course_desc_en')}`],
  titles: [`${i18n.t('course_name_kz')}`, `${i18n.t('course_name_ru')}`, `${i18n.t('course_name_en')}`],
  fields: ['title_kk', 'title_ru', 'title_en'],
  checkboxesLbl: [`${i18n.t('display_the_course')}`, `${i18n.t('send_notif_course')}`, `${i18n.t('add_unverified')}`],
  checkboxes: ['show', 'is_need_mailing', 'can_sort'],
  // about courses
  details: ['tag_name', 'city', 'name', 'name', 'degree_name', 'name', 'format_name'],
  objName: ['tag', 'city', 'university', 'univer_tag', 'degree', 'speciality', 'format'],
  formatLesson: ['video', 'livestream', 'exam'],
  cookiesName: ['_prgrmCtgrSv', '_prgrmDgrSv', '_prgrmUnvrSv', '_prgrmFcltSv', '_prgrmCtSv', '_prgrmSrchSv', '_prgrmLngSv', '_prgrmFrmtSv', '_prgrmUnvrTgSv']
  // schedule
}

export default datas
