<template>
  <div class="guest_page">
    <GuestHeader />
    <main>
      <router-view />
    </main>
    <FooterPage />
  </div>
</template>

<script>
import FooterPage from '@/components/FooterPage.vue'
import GuestHeader from '@/components/guest/GuestHeader.vue'

export default {
  name: 'GuestLayout',
  components: { GuestHeader, FooterPage }
}
</script>
