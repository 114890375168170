<template>
  <div>
    <PreLoader v-if="preloader"/>
    <div>
      <discleim-component
        v-if="mood === 0"
        @set-data="fine"/>
      <education-component
        v-if="mood === 1"
        :education="education"
        @set-data="setEduData"
        @warn-toast="activateWarnToast"
        @error-toast="activateErrorToast"/>
      <certif-component
        v-if="mood === 3"
        :certificate="certificate"
        @step-back="stepBack"
        @set-data="setCertifData"
        @warn-toast="activateWarnToast"
        @error-toast="activateErrorToast"/>
      <language-component
        v-if="mood === 2"
        :languages="languages"
        @step-back="stepBack"
        @set-data="setLangData"
        @warn-toast="activateWarnToast"
        @error-toast="activateErrorToast"/>
    </div>
    <SuccessToast
      v-if="isToastSuccess"
      :text="text"
      @close="closeToast"/>
    <ErrorToast
      v-if="isToastError"
      :text="text"
      @close="closeToast"/>
    <WarningToast
      v-if="isToastWarn"
      :text="text"
      @close="closeToast"/>
  </div>
</template>

<script>
import SuccessToast from '@/components/toast/SuccessToast'
import ErrorToast from '@/components/toast/ErrorToast'
import WarningToast from '@/components/toast/WarningToast'
import PreLoader from '@/components/preloader/PreLoader.vue'
import CertifComponent from './CertifComponent.vue'
import EducationComponent from './EducationComponent.vue'
import LanguageComponent from './LanguageComponent.vue'
import DiscleimComponent from './DiscleimComponent.vue'

export default {
  name: 'EduComponent',
  components: { WarningToast, SuccessToast, ErrorToast, PreLoader, CertifComponent, EducationComponent, LanguageComponent, DiscleimComponent },
  data () {
    return {
      isToastSuccess: false,
      isToastError: false,
      isToastWarn: false,
      text: '',
      preloader: false,
      mood: 0,
      education: [{
        degree: 0,
        profession_name: '',
        start: '',
        end: '',
        assessment_form: '',
        score: 0,
        user: JSON.parse(localStorage.getItem('uData')).id
      }],
      certificate: [{
        name: '',
        total_score: 0,
        user: JSON.parse(localStorage.getItem('uData')).id,
        language: ''
      }],
      languages: [{
        language: '',
        level: '',
        id: null
      }]
    }
  },
  methods: {
    stepNext () {
      this.mood === 2 ? this.mood += 1 : this.mood += 1
    },
    stepBack () {
      this.mood === 3 ? this.mood -= 1 : this.mood -= 1
    },
    fine () {
      this.mood = 1
    },
    // settingData
    setEduData (item) {
      this.stepNext()
      this.education = item
    },
    setCertifData (item) {
      this.certificate = item
      this.loopEdu()
    },
    setLangData (item) {
      this.languages = item
      this.stepNext()
    },
    // toast
    interVal: function () {
      setTimeout(() => {
        this.closeToast()
      }, 2000)
    },
    activateErrorToast: function (text) {
      this.text = text
      this.isToastError = true
      this.interVal()
    },
    activateSuccessToast: function (text) {
      this.text = text
      this.isToastSuccess = true
      this.interVal()
    },
    activateWarnToast: function (text) {
      this.text = text
      this.isToastWarn = true
      this.interVal()
    },
    closeToast: function () {
      this.isToastSuccess = false
      this.isToastError = false
    },
    // loopForSaving
    async loopEdu () {
      this.preloader = true
      for (const item of this.education) {
        await this.addEducation(item)
      }
      this.loopLang()
    },
    async loopLang () {
      for (const item of this.languages) {
        await this.addLanguage(item)
      }
      this.loopCertif()
    },
    async loopCertif () {
      if (this.certificate[0].name) {
        for (const item of this.certificate) {
          await this.addCertificate(item)
        }
      }
      this.$emit('check-edu')
      this.activateSuccessToast(`${this.$t('toast_edu_add_success')}`)
      this.preloader = false
    },
    // axios functions
    async addEducation (item) {
      item.score = Number(item.score)
      item.start = Number(item.start)
      item.end = Number(item.end)
      await this.$store.dispatch('addEducation', item)
        .then((resp) => {
          console.log(resp)
        })
        .catch(err => {
          this.text = err.response.data.message || err.response.data.detail + '!'
          this.activateErrorToast(this.text)
          this.preloader = false
        })
    },
    async addCertificate (item) {
      await this.$store.dispatch('addCertificate', item)
        .then(() => {
          // this.$emit('check-edu')
        })
        .catch(err => {
          this.text = err.response.data.message || err.response.data.detail + '!'
          this.activateErrorToast(this.text)
          this.preloader = false
        })
    },
    async addLanguage (item) {
      await this.$store.dispatch('addLanguage', item)
        .then((resp) => {
          console.log(resp)
        })
        .catch(err => {
          this.text = err.response.data.message || err.response.data.detail + '!'
          this.activateErrorToast(this.text)
          this.preloader = false
        })
    }
  },
  mounted () {
  }
}
</script>
