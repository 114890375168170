<template>
  <header class="flex guest_width guest_page_header">
    <LogoTip />
    <div class="guest_page_header_bttn">
      <LocalizationPage :isGuest="true"/>
      <router-link to="/login"
        class="bttn-primary bttn bttn_width brdr_r_8 medium_bold_m"
        style="width: 89px;">
        <p>{{$t('auth_enter')}}</p>
      </router-link>
      <router-link to="/user-registrate"
        class="bttn-primary bttn bttn_width brdr_r_8 medium_bold_m">
        <p>{{$t('registrate')}}</p>
      </router-link>
    </div>
  </header>
</template>

<script>
import LocalizationPage from '@/components/LocalizationPage.vue'
import LogoTip from './LogoTip.vue'

export default {
  name: 'GuestHeader',
  components: { LocalizationPage, LogoTip }
}
</script>
