<template>
  <div class="header">
    <div class="header_block">
      <HideMenu
        @hide-or-show="menuOpen"
        :isOpen="isOpen"/>
      <LogoTip class="logotip"/>
      <div class="flex local">
        <a href="https://t.me/grafenkz_bot" target="_blank" class="telegram flex" v-if="personal_code && uData === 'student'">
          <img src="/img/telegram-removebg-preview.png" alt="tg">
          <p class="medium_bold_m">{{personal_code}}</p>
        </a>
        <localization-page />
        <UsersBlock
          @my-profile="toMyProfile"/>
      </div>
    </div>
  </div>
</template>

<script>
import UsersBlock from '@/components/UsersBlock'
import LogoTip from '@/components/guest/LogoTip.vue'
import HideMenu from '@/components/HideMenu'
import LocalizationPage from './LocalizationPage.vue'

export default {
  name: 'ForMainLayout',
  components: { UsersBlock, LogoTip, HideMenu, LocalizationPage },
  props: {
    isOpen: Boolean
  },
  data () {
    return {
      personal_code: '',
      uData: JSON.parse(localStorage.getItem('uData')).rle
    }
  },
  methods: {
    menuOpen: function (item) {
      this.$emit('hide-or-show', item)
    },
    toMyProfile: function () {
      this.$emit('my-profile')
    },
    async getPersonalCode () {
      await this.$store.dispatch('getCode')
        .then((response) => {
          this.personal_code = response.data.code
        })
        .catch(err => {
          this.error = err.response.data.detail
        })
    }
  },
  mounted () {
    this.getPersonalCode()
  }
}
</script>
